
import { AdultParticipant } from '@/common/types/AdultParticipant.type';
import { apiService } from '@/services/api.service';
import { kioskModule } from '@/store/kiosk.vuex-module';
import { differenceInYears } from 'date-fns';
import { Options, Vue } from 'vue-class-component';
import YesNoInput from '@/modules/consent/components/form/yes-no-input.component.vue';
import TextInput from '@/modules/consent/components/form/text-input.component.vue';
import CustomFieldInput from '@/modules/consent/components/form/custom-field-input.component.vue';
import DateInput from '@/modules/consent/components/form/date-input.component.vue';
import { CustomField } from '@/common/types/client.type';
import { Watch } from 'vue-property-decorator';
import { format } from 'date-fns';
import { OnClickOutside } from '@vueuse/components';
import SkiRentalInput from '@/modules/consent/components/form/ski-rental-input.component.vue';
import BooleanInput from '@/modules/consent/components/form/bool-input.component.vue';
import { UserConsentOrderTimes } from '@/common/types/UserConsentOrderTimes.type';

type CountryOption = {
	CountryKey: number;
	Code: number;
	Abbreviation: string;
	Name: string;
};

@Options({
	name: 'AdultView',
	components: { YesNoInput, CustomFieldInput, TextInput, DateInput, OnClickOutside, SkiRentalInput, BooleanInput },
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			(vm as AdultView).showBackBtn = from.name == 'AppointmentsView';
		});
	},
})
export default class AdultView extends Vue {
	isLoading = true;
	showBackBtn = false;
	consenter!: AdultParticipant;
	customFieldResponses: CustomField[] = [];
	pendingConsent: any | null = null;
	autoLoaded = false;
	errors = {
		email: false,
		firstName: false,
		lastName: false,
		dateOfBirth: false,
		zipCode: false,
		skiExp: false,
		skiHeight: false,
		skiWeight: false,
		skiShoes: false,
	};

	emailValidation = {
		showModal: false,
		emailCache: '',
		isValidating: false,
	};
	addingMinors = false;
	formattedLocation = '';
	manualLocationEntry = false;
	countryOptions: CountryOption[] = [];

	timer: ReturnType<typeof setTimeout> | null = null;

	get c(): AdultParticipant {
		return this.consenter;
	}
	// #region GETTERS / SETTERS
	get customFields() {
		return this.consenter.customFields;
	}

	get adultsOnlyConsent(): boolean {
		return kioskModule.order?.CheckInOrderEntities?.every((a) => a.AdultsOnly) ?? false;
	}

	get partyName() {
		// return kioskModule.order ? `Check-In for ${kioskModule.order.LastName} Party` : 'Checkin';
		return kioskModule.order?.LastName ?? null;
	}

	get editCityState(): boolean {
		return this.consenter.countryKey == 196 ? false : true;
	}

	get states(): string[] {
		return [
			'AL',
			'AK',
			'AS',
			'AZ',
			'AR',
			'CA',
			'CO',
			'CT',
			'DE',
			'DC',
			'FM',
			'FL',
			'GA',
			'GU',
			'HI',
			'ID',
			'IL',
			'IN',
			'IA',
			'KS',
			'KY',
			'LA',
			'ME',
			'MH',
			'MD',
			'MA',
			'MI',
			'MN',
			'MS',
			'MO',
			'MT',
			'NE',
			'NV',
			'NH',
			'NJ',
			'NM',
			'NY',
			'NC',
			'ND',
			'MP',
			'OH',
			'OK',
			'OR',
			'PW',
			'PA',
			'PR',
			'RI',
			'SC',
			'SD',
			'TN',
			'TX',
			'UT',
			'VT',
			'VI',
			'VA',
			'WA',
			'WV',
			'WI',
			'WY',
		];
	}

	get zipCode() {
		return this.consenter.zipCode;
	}

	set zipCode(z: string) {
		this.consenter.zipCode = z;
		this.getZipLocation();
	}

	get hasCheckins() {
		return !!kioskModule.order?.TotalPeopleCheckedIn;
	}

	get adultAgeMsg() {
		return this.adultAge ? `You must be at least ${this.adultAge} years old to complete this form` : '';
	}

	get adultAge() {
		return kioskModule.adultAge;
	}

	get disableNext() {
		return Object.values(this.errors).every((v) => !v);
	}

	get skiRentalFields() {
		return kioskModule.skiRentalFieldResponses;
	}

	get collectSkiRentalInfo() {
		return kioskModule.collectSkiRentalInfo;
	}

	//#endregion

	@Watch('addingMinors') addMinorsWatcher(v: boolean) {
		if (!v) kioskModule.clearMinorParticipants();
	}

	// validates email format
	validateEmail() {
		// eslint-disable-next-line
		let validFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.consenter.email);
		this.errors.email = validFormat ? false : true;
	}

	// Verifies email is in valid format before calling validation API.
	async verifyEmail() {
		this.emailValidation.isValidating = true;
		return await apiService
			.get('isValidEmail', {
				email: this.consenter.email,
			})
			.then((valid: boolean) => {
				return valid;
			})
			.catch(() => {
				return false;
			});
	}
	checkExisting() {
		if (this.autoLoaded) return;
		if (this.timer) clearTimeout(this.timer);
		if (this.errors.dateOfBirth || this.errors.firstName || this.errors.lastName) {
			return;
		}
		this.timer = setTimeout(() => {
			if (this.consenter.dateOfBirth.day && this.consenter.dateOfBirth.month != null && this.consenter.dateOfBirth.year && this.consenter.firstName != '' && this.consenter.lastName != '') {
				let ds = `${this.consenter.dateOfBirth.month + 1}/${this.consenter.dateOfBirth.day}/${this.consenter.dateOfBirth.year}`;
				kioskModule.searchWaiver({ firstName: this.consenter.firstName, lastName: this.consenter.lastName, dateOfBirth: ds, orderKey: kioskModule.orderKey }).then((consenter) => {
					if (consenter) {
						this.pendingConsent = consenter;
						this.autoLoaded = true;
					} else {
						console.error('No waiver found');
					}
				});
			} else {
				console.log('Fields not set');
				console.log(this.consenter.dateOfBirth.day, this.consenter.dateOfBirth.month, this.consenter.dateOfBirth.year, this.consenter.firstName, this.consenter.lastName);
			}
		}, 500);
	}
	editPendingConsent() {
		let c = this.pendingConsent;
		console.log(this.pendingConsent);
		if (c) {
			const session = kioskModule.session;
			session.isEditing = true;
			// map consenter
			const consenter: AdultParticipant = {
				consentKey: c.ConsentKey,
				consentGuid: c.ConsentGuid,
				consentMinors: c.ConsentMinors.map((minor: any) => {
					return {
						firstName: minor.FirstName,
						lastName: minor.LastName,
						email: '',
						dateOfBirth: {
							day: new Date(minor.DateOfBirth).getDate(),
							month: new Date(minor.DateOfBirth).getMonth(),
							year: new Date(minor.DateOfBirth).getFullYear(),
							formatted: format(new Date(minor.DateOfBirth), 'M-d-yyyy'),
						},
						guid: minor.Guid,
						consentMinorGuid: minor.ConsentMinorGUid,
						canDelete: minor.canDelete,
						customFields: [],
						consentMinorKey: minor.ConsentMinorKey,
						skiExperienceKey: minor.DIN_ExperienceLevelHistoryKey,
						skiHeightKey: minor.DIN_HeightHistoryKey,
						skiWeightKey: minor.DIN_WeightHistoryKey,
						skiShoeSizeKey: minor.DIN_ShoeSizeHistoryKey,
					};
				}),
				customFields: c.CustomFields,
				firstName: c.FirstName,
				lastName: c.LastName,
				isParticipating: c.IsParticipating,
				email: c.Email,
				emailOptIn: c.EmailOptIn,
				zipCode: c.ZipCode,
				city: c.City,
				state: c.State,
				countryKey: c.CountryKey,
				signatureBase64: c.SignatureBase64,
				skiExperienceKey: c.DIN_ExperienceLevelHistoryKey,
				skiHeightKey: c.DIN_HeightHistoryKey,
				skiWeightKey: c.DIN_WeightHistoryKey,
				skiShoeSizeKey: c.DIN_ShoeSizeHistoryKey,
				dateOfBirth: {
					day: new Date(c.DateOfBirth).getDate(),
					month: new Date(c.DateOfBirth).getMonth(),
					year: new Date(c.DateOfBirth).getFullYear(),
					formatted: format(new Date(c.DateOfBirth), 'yyyy/MM/dd'),
				},
				languageKey: c.LanguageKey,
				guid: c.Guid,
			};
			session.consenter = consenter;

			// map participating activities
			const assignedActivities = new Set<string>();
			c.UserConsentOrderEntityTimes.forEach((time: UserConsentOrderTimes) => {
				const timeParticipants: string[] = [];

				if (time.Consentees.Consentor.IsParticipating) timeParticipants.push(time.Consentees.Consentor.Guid); // push consentor

				timeParticipants.push(...time.Consentees.ConsentMinors.map((m) => m.Guid)); // push minors

				if (timeParticipants.length) {
					session.activityParticipants[time.OrderEntityTimeKey] = timeParticipants;
					assignedActivities.add(time.OrderEntityTimeKey);
				}
			});

			session.editActivities = Array.from(assignedActivities);
			if (consenter.consentMinors.length) {
				this.addingMinors = true;
				console.log('SETTING MINORS');
			}
			session.signature = c.signatureBase64 ?? '';
			kioskModule.updateAdult(session.consenter);
			kioskModule.saveSession(session);
			this.emailValidation.emailCache = session.consenter.email;
			this.zipCode = consenter.zipCode;
			this.consenter = consenter;
			this.pendingConsent = null;
			this.getZipLocation();
			console.log(session);
		}
	}
	validateBirthday() {
		const enteredBday = this.consenter.dateOfBirth.month != null && this.consenter.dateOfBirth.day != null && this.consenter.dateOfBirth.year != null;
		if (enteredBday) {
			const dob = new Date(this.consenter.dateOfBirth.year!, this.consenter.dateOfBirth.month!, this.consenter.dateOfBirth.day!);
			this.errors.dateOfBirth = differenceInYears(new Date(), dob) < kioskModule.adultAge;
			return;
		}
		this.errors.dateOfBirth = true;
	}

	validateZip() {
		if (this.consenter.countryKey == 196) {
			this.errors.zipCode = this.manualLocationEntry ? !(!!this.consenter.city && !!this.consenter.state) : this.consenter.zipCode.length < 5;
		} else {
			this.errors.zipCode = !this.consenter.zipCode.length;
		}
	}

	validateSkiRentalFields(field?: string) {
		this.errors.skiExp = this.consenter.skiExperienceKey == undefined;
		this.errors.skiHeight = this.consenter.skiHeightKey == undefined;
		this.errors.skiWeight = this.consenter.skiWeightKey == undefined;
		this.errors.skiShoes = this.consenter.skiShoeSizeKey == undefined;
	}

	created(): void {
		this.isLoading = true;

		if (kioskModule.isEditing) {
			kioskModule.updateOrderKey(this.$route.params.orderKey as string);
		} else {
			// kioskModule.resetSession();
		}

		kioskModule.startSession();
		kioskModule.updateLastPage('AdultCheckin');

		this.consenter = kioskModule.consenter;
		this.addingMinors = this.consenter.consentMinors ? this.consenter.consentMinors.length > 0 : false;
		this.formattedLocation = this.consenter.city && this.consenter.state && this.consenter.zipCode ? `${this.consenter.city}, ${this.consenter.state} ${this.consenter.zipCode}` : '';
		this.emailValidation.emailCache = this.consenter.email ? this.consenter.email.toString() : '';

		apiService
			.get('getCountries', {})
			.then((res) => {
				if (res) this.countryOptions = res;
			})
			.finally(() => (this.isLoading = false));
	}
	mounted(): void {
		let cf = kioskModule._client?.ConsentForm.CustomFields;
		console.log('Checking', cf, this.consenter.customFields.length);
		if (cf && !this.consenter.customFields.length) this.consenter.customFields = cf;
	}

	async getZipLocation(): Promise<boolean> {
		// lookup zip-code for US and Canada
		if (this.consenter.zipCode.length >= 5 && (this.consenter.countryKey == 196 || this.consenter.countryKey == 31)) {
			return await apiService
				.googleGet('https://maps.googleapis.com/maps/api/geocode/json', {
					address: `${this.consenter.zipCode},${this.countryOptions.find((c) => c.CountryKey == this.consenter.countryKey)!.Name}}`,
					key: (window as any).config.GoogleApiKey,
				})
				.then((res) => {
					if (res.status == 'OK') {
						try {
							this.consenter.city = res.results[0].address_components[1].short_name;
							this.formattedLocation = res.results[0].formatted_address;
							this.consenter.state = res.results[0].address_components.find((ac: any) => this.states.includes(ac.short_name)).short_name;
							this.manualLocationEntry = false;
							return true;
						} catch {
							console.error('Could not lookup location.');
							this.formattedLocation = 'Enter City & State';
							this.manualLocationEntry = true;
							return false;
						}
					} else {
						this.formattedLocation = 'Enter City & State';
						this.manualLocationEntry = true;
						return false;
					}
				});
		}
		return false;
	}

	editCompleted() {
		this.$router.push({ name: 'Completed' });
	}

	onNext() {
		const formValid = this.validateForm();
		if (formValid) {
			if (this.emailValidation.emailCache == this.consenter.email) this.onContinue();
			// email has been validated by user
			else
				this.verifyEmail().then((verified: boolean) => {
					if (verified) this.onContinue();
					else {
						this.emailValidation.emailCache = this.consenter.email.toString();
						this.emailValidation.showModal = true;
					}
				});
		} else {
			setTimeout(() => {
				document.getElementsByClassName('invalid')[0]?.scrollIntoView({ behavior: 'smooth' });
			}, 0);
		}
	}

	onContinue() {
		this.consenter.customFields = this.customFieldResponses;
		this.consenter.dateOfBirth.formatted = `${this.consenter.dateOfBirth.year}/${this.consenter.dateOfBirth.month! + 1}/${this.consenter.dateOfBirth.day}`;

		// if not participating & no minors -> flip participation (user must be participating to fill out waiver)
		if (!this.consenter.isParticipating && !kioskModule.minorParticipants.length) this.consenter.isParticipating = true;

		kioskModule.updateAdult(this.consenter);

		const route = this.addingMinors ? 'MinorsCheckin' : this.$route.query.unassigned ? 'WaiverForms' : 'ActivitySelection';
		this.$router.push({ name: route, query: { ...this.$route.query } });
	}

	validateForm(): boolean {
		this.validateEmail();
		this.validateBirthday();
		this.validateZip();
		if (this.collectSkiRentalInfo) this.validateSkiRentalFields();

		this.errors.firstName = !this.consenter.firstName.replaceAll(' ', '').length;
		this.errors.lastName = !this.consenter.lastName.replaceAll(' ', '').length;

		let formValid = Object.values(this.errors).every((v) => !v);

		this.customFieldResponses = [];
		this.customFields?.forEach((cf, i) => {
			var cfElementMatches = this.$refs[`cf${i}`] as any;
			const response = (cfElementMatches[0] as CustomFieldInput).validate();
			if (response) this.customFieldResponses.push(response);
			else formValid = false;
		});
		return formValid;
	}

	tryEmailAgain() {
		this.emailValidation.showModal = false;
		this.emailValidation.isValidating = false;
		document.getElementById('email')?.focus();
		(document.getElementById('email') as HTMLInputElement)?.select();
	}
}
